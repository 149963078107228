import { useParams } from "@solidjs/router";
import { ShowScheduleViewProps } from "../../model";
import { Match, Show, Switch, createEffect, createSignal, splitProps } from "solid-js";
import { Atom, log, state } from ":mods";
import { getSchedulesList } from "../../api";
import { ListSchedule } from "./list-schedule";

export function ShowSchedule(props: ShowScheduleViewProps) {
  const [local, others] = splitProps(props, ["$"]);
  const is_student = local.$.actions.getTokenType() === local.$.CONST.ACCOUNT.TOKEN_TYPE.student;
  const params = useParams();
  const stage_id = is_student ? (local.$.actions as any).getStageSelected() : params.id;
  const $list = state.createAsync([getSchedulesList, stage_id]);
  local.$.actions.setLayout({
    title: "Schedules",
  });

  createEffect(() => {
    if ($list.state.success) {
      const value = $list.unwrap.value;
      local.$.actions.updateLayout({
        title: value.data?.title,
      });
    }
  });
  return (
    <Switch>
      <Match when={$list.state.loading}>
        <div class="flex w-full h-full items-center justify-center">
          <Atom.Loader.Platform
            title="Museum Taining Modules"
            msg="Loading stage modules..."
            class="relative flex$col extend$ fight$"
          />
        </div>
      </Match>
      <Match when={$list.state.failed}>an error occured while getting list of events</Match>
      <Match when={$list.state.success}>
        <ListSchedule schedulesList={$list.unwrap.value.data} />
      </Match>
    </Switch>
  );
}
